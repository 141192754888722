import { defineStore } from 'pinia'
import useCustomFetch from '~/composables/useCustomFetch'
import { STATUS_OK } from '~/constants/apiStatus'
import { useAlertStore } from '~/stores'

export const useUserStore = defineStore(
    'userStore',
    () => {
        const fetcher = useCustomFetch()
        const useAlertStoreInstance = useAlertStore()
        const user = ref(null)
        const balance = ref(0)
        const userActivePackage = ref(false)
        const runtimeConfig = useRuntimeConfig()
        // const useNuxtAppInstance = useNuxtApp()

        const isLoggedIn = ref(false)
        const refreshId = ref('')
        const navigateAfterLogin = ref('/account/deposit')
        // eslint-disable-next-line no-unused-vars
        const showLoggedOutAlert = useState('showLoggedOutAlert', () => false)

        const route = useRoute()
        const utms = ref({})
        const toSet = {
            aff_id: route?.query.a ?? utms?.aff_id,
            source: route?.query.source ?? utms?.source,
            utm_campaign: route?.query.utm_campaign ?? utms?.utm_campaign,
            utm_source: route?.query.utm_source ?? utms?.utm_source,
            utm_medium: route?.query.utm_medium ?? utms?.utm_medium,
            utm_term: route?.query.utm_term ?? utms?.utm_term,
            utm_content: route?.query.utm_content ?? utms?.utm_content,
            querystring: route?.query.querystring ?? utms?.querystring,
        }
        utms.value = toSet

        const playerErrorHandler = (payload) => {
            const reg500 = '50[0-9]'
            const reg400 = '40[0-9]'
            const globalRegex500 = new RegExp(reg500, 'g')
            const globalRegex400 = new RegExp(reg400, 'g')
            if (globalRegex500.test(payload?.value?.statusCode)) {
                useAlertStoreInstance.showMessage({
                    message_key: 'error.server_is_busy',
                    type: 'ERROR',
                })
            } else if (globalRegex400.test(payload?.value?.statusCode)) {
                useAlertStoreInstance.showMessage({
                    message_key: 'error.server_is_busy',
                    type: 'ERROR',
                })
            } else if (payload?.value?.data?.data?.message) {
                // the payload is error object from fetcher
                useAlertStoreInstance.showMessage({
                    message: payload?.value?.data?.data?.message,
                    type: 'ERROR',
                })
            } else if (payload?.value?.message) {
                // the payload is data object from fetcher
                useAlertStoreInstance.showMessage({
                    message: payload?.value?.message,
                    type: 'ERROR',
                })
            } else {
                useAlertStoreInstance.showMessage({
                    message_key: 'error.unknown',
                    type: 'ERROR',
                })
                console.error('show error message', payload?.value?.message)
            }
        }

        const verifyUsername = async (username) => {
            try {
                const { data, pending, error } = await fetcher.post(
                    '/verifyUsername',
                    {
                        username: username,
                    }
                )
                // response {exist: true}
                if (data.value.exist) {
                    useAlertStoreInstance.showMessage({
                        message: 'User exist',
                        type: 'SUCCESS',
                    })
                } else {
                    useAlertStoreInstance.showMessage({
                        message: 'User does not exist',
                        type: 'ERROR',
                    })
                }
                return { data, pending, error }
            } catch (error) {
                // TODO: show error message
                if (error?.value?.data?.data?.message) {
                    useAlertStoreInstance.showMessage({
                        message: error?.value?.data?.data?.message,
                        type: 'ERROR',
                    })
                    // console.error(
                    //     'show error message',
                    //     error?.value?.data?.data?.message
                    // )
                } else {
                    console.error('show error message', error)
                }
                return { data: null, pending: false, error }
            }
        }
        const register = async (payload) => {
            try {
                const { data, pending, error } = await fetcher.post(
                    '/register',
                    {
                        username: payload.username,
                        fullname: payload.fullname,
                        password: payload.password,
                        confirmPassword: payload.confirmPassword,
                        phone: payload.phone,
                        ref_code: payload.ref_code,
                        ...utms.value,
                    }
                )
                // set user only if status is OK
                if (data.value?.status === STATUS_OK) {
                    setUserData(data?.value?.data[0])
                } else {
                    throw data
                }
                return { data, pending, error }
            } catch (error) {
                // TODO: show error message
                playerErrorHandler(error)
                // a fallback
                return { data: null, pending: false, error }
            }
        }

        const login = async (payload, callback) => {
            try {
                const { data, pending, error } = await fetcher.post('/login', {
                    username: payload.username,
                    password: payload.password,
                })
                // set user only if status is ok
                if (data.value.status === STATUS_OK) {
                    setUserData(data?.value?.data[0])
                    if (data?.value?.data[0]?.balance > 0) {
                        navigateAfterLogin.value = ''
                    } else {
                        navigateAfterLogin.value = ''
                    }

                    // save username & saveAccount to local storage
                    if (payload.saveAccount) {
                        localStorage.setItem('username', payload.username)
                        localStorage.setItem('saveAccount', '1')
                    } else {
                        localStorage.setItem('username', '')
                        localStorage.setItem('saveAccount', '0')
                    }
                    callback?.(data?.value?.data[0])
                } else if (data?.value?.status !== 'OK') {
                    playerErrorHandler(data)
                    // clearUserData()
                } else {
                    await logout()
                    clearUserData()
                    console.log('no OK status')
                    throw data
                }
                return { data, pending, error }
            } catch (error) {
                // TODO: show error message
                playerErrorHandler(error)
                // return { data: null, pending: false, error }
            }
        }

        const loginByToken = async (queryParams = {}) => {
            try {
                const { data, error } = await useFetch('/api/v1/user/login', {
                    query: queryParams,
                })
                if (data.value.status === STATUS_OK) {
                    setUserData(data?.value?.data[0])
                    if (data?.value?.data[0]?.balance > 0) {
                        navigateAfterLogin.value = '/'
                    } else {
                        navigateAfterLogin.value = '/account/deposit'
                    }
                }
                if (error.value) {
                    throw error
                }
                return { data }
            } catch (err) {
                console.error(err)
            }
        }

        const logout = async () => {
            try {
                const { data, pending, error } = await fetcher.post('/logout')
                clearUserData()
                return { data, pending, error }
            } catch (error) {
                clearUserData()
                return { data: null, pending: false, error }
            } finally {
                console.clear('goodbye')
            }
        }

        const refresh = async () => {
            try {
                const { data, pending, error } = await fetcher.get('/refresh')
                if (data?.value?.user) {
                    setUserData(data?.value?.user)
                    // useNuxtAppInstance.$io.disconnect().connect()
                } else {
                    console.log('refresh is ok but no user')
                    throw error
                }
                return { data, pending, error }
            } catch (error) {
                // clearUserData()
                // showLoggedOutAlert.value = true
                // navigateTo('/')
                return { data: null, pending: false, error: error }
            }
        }
        // TODO to be used
        // const { refresh } = useApi('/refresh', {
        //     immediate: false,
        //     onResponse({ response }) {
        //         if (response._data?.user) {
        //             setUserData(response?._data?.user)
        //         }
        //     },
        //     onResponseError({ response }) {
        //         console.log('response', response)

        //         if (response.status === 401) {
        //             clearUserData()
        //         }
        //     },
        // })

        const updatePassword = async (payload) => {
            return await fetcher.post('/updatePassword', payload)
        }

        const setUserData = (payload) => {
            user.value = payload
            isLoggedIn.value = true
            balance.value = payload.balance
            userActivePackage.value = payload.package_id
            clearRefresh()
            startRefresh()
        }
        const clearUserData = () => {
            user.value = null
            emailToVerify.value = ''
            isLoggedIn.value = false
            clearRefresh()
        }

        const startRefresh = () => {
            refreshId.value = setInterval(
                () => refresh(),
                runtimeConfig.public.refreshInterval
            )
        }
        const clearRefresh = () => {
            clearInterval(refreshId.value)
        }

        const emailToVerify = ref('')
        const verifyEmail = async (queryParams) => {
            const { data } = await fetcher.get('/user/getOTP', queryParams)
            if (data.value.status === 'OK') {
                emailToVerify.value = queryParams.email
                return { data: data }
            } else {
                throw data
            }
        }

        const verifyEmailOtp = async (payload) => {
            return await fetcher.post('/user/emailOTPVerification', payload)
        }

        const verifyTelegram = () => {
            if (import.meta.client) {
                window.open(
                    `https://t.me/${useRuntimeConfig().public.BRAND_NAME.toLowerCase()}_channel_bot`,
                    '_blank'
                )
            }
        }

        const verifyTelegramOtp = async (payload) => {
            return await fetcher.post('user/telegramOTPVerification', payload)
        }

        return {
            user,
            userActivePackage,
            balance,
            isLoggedIn,
            refreshId,
            navigateAfterLogin,
            verifyUsername,
            register,
            login,
            logout,
            refresh,
            startRefresh,
            clearRefresh,
            clearUserData,
            updatePassword,

            // verifyEmail
            emailToVerify,
            verifyEmail,
            verifyEmailOtp,

            // verifyTelegram
            verifyTelegram,
            verifyTelegramOtp,

            //loginByToken
            loginByToken,
            utms,
        }
    },
    {
        persist: {
            storage: persistedState.localStorage,
        },
    }
)
